import * as React from 'react';
import { IComponentController } from '@wix/editor-elements-types';

const mapActionsToProps: IComponentController = ({ updateProps }) => ({
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => {
    updateProps({
      value: event.target.value,
    });
  },
});

export default mapActionsToProps;
