import * as React from 'react';
import classNames from 'classnames';
import { ComboBoxInputOption } from '@wix/thunderbolt-components/src/components/ComboBoxInput/ComboBoxInput';
import {
  IComboBoxInputImperativeActions,
  IComboBoxInputProps,
} from '../ComboBoxInput.types';
import { HAS_CUSTOM_FOCUS_CLASSNAME } from '../../../core/commons/a11y';

const skinsWithoutLabel = [
  'ResponsiveComboBoxInputSkin',
  'ComboBoxInputVerticalMenuSkin',
];

type IComboBoxInputBaseRenderProp = Pick<
  IComboBoxInputProps,
  'id' | 'onClick' | 'onDblClick' | 'onMouseEnter' | 'onMouseLeave'
> & {
  content: React.ReactNode;
  className: string;
  ariaLabel?: string;
};

type IComboBoxInputBaseProps = IComboBoxInputProps & {
  styles: { [key: string]: string };
  children(props: IComboBoxInputBaseRenderProp): React.ReactElement;
};

const generatePlaceholder = (
  placeholder: IComboBoxInputProps['placeholder'],
  className: string,
) => [
  <option value={''} disabled={true} className={className} key={'placeholder'}>
    {placeholder.text}
  </option>,
];

const generateOptions = (
  options: Array<ComboBoxInputOption>,
  className: string,
) => {
  return options.map((option: ComboBoxInputOption) => (
    <option value={option.value} className={className} key={option.key}>
      {option.text}
    </option>
  ));
};

const ComboBoxInputSkin: React.ForwardRefRenderFunction<
  IComboBoxInputImperativeActions,
  IComboBoxInputBaseProps
> = (props, ref) => {
  const {
    id,
    skin,
    label,
    styles,
    value,
    options,
    children,
    required,
    isDisabled,
    placeholder,
    shouldShowValidityIndication,
    validateValueAndShowIndication = () => {},
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
    onClick = () => {},
    onDblClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
  } = props;

  const inputRef = React.useRef<HTMLSelectElement>(null);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
      setCustomValidity: (message: string) => {
        inputRef.current?.setCustomValidity(message);
      },
    };
  });

  const _onChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    onChange(event);
    validateValueAndShowIndication({
      value: event.target.value,
    });
  };

  const _onMouseEnter: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseEnter(event);
    }
  };

  const _onMouseLeave: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseLeave(event);
    }
  };

  const hideLabel = skinsWithoutLabel.includes(skin);
  const placeholderValue = placeholder && placeholder.value;
  const isLegacyPlaceholderSelected = value === placeholderValue;
  const isPlaceholderSelected =
    isLegacyPlaceholderSelected || (placeholderValue && value === '');

  const rootClassName = classNames(styles[skin], styles.root, {
    [styles.hasLabel]: !!label,
    [styles.withRequiredIndication]: required,
    [styles.withValidationIndication]: shouldShowValidityIndication,
  });

  const selectClassName = classNames(
    styles.select,
    HAS_CUSTOM_FOCUS_CLASSNAME,
    {
      [styles.extendedPlaceholderStyle]: isPlaceholderSelected,
    },
  );

  const content = (
    <>
      {hideLabel ? null : (
        <label className={styles.label} htmlFor={`${id}collection`}>
          {label}
        </label>
      )}
      <div className={styles.selectorWrapper}>
        <select
          ref={inputRef}
          className={selectClassName}
          id={`${id}collection`}
          onFocus={onFocus}
          onChange={_onChange}
          onBlur={onBlur}
          disabled={isDisabled}
          required={required}
          value={value}
        >
          {generatePlaceholder(
            placeholder,
            classNames(styles.option, styles.placeholder),
          ).concat(generateOptions(options, styles.option))}
        </select>
        <div className={styles.arrow}>
          <div className={styles.svgContainer}>
            <svg
              className={styles.icon}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 9.2828 4.89817"
            >
              <title>arrow&amp;amp;v</title>
              <path d="M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z" />
            </svg>
          </div>
        </div>
      </div>
    </>
  );

  return children({
    id,
    className: rootClassName,
    onClick,
    onDblClick,
    onMouseEnter: _onMouseEnter,
    onMouseLeave: _onMouseLeave,
    content,
    ariaLabel: label,
  });
};

export default React.forwardRef(ComboBoxInputSkin);
