import * as React from 'react';
import {
  IComboBoxInputImperativeActions,
  IComboBoxInputProps,
} from '../../../ComboBoxInput.types';
import ComboBoxInputBase from '../../ComboBoxInputBase';
import styles from './style/ComboBoxInputVerticalMenuSkin.scss';

const ComboBoxInputNavigationSkin: React.ForwardRefRenderFunction<
  IComboBoxInputImperativeActions,
  IComboBoxInputProps
> = (props, ref) => {
  return (
    <ComboBoxInputBase ref={ref} {...props} styles={styles}>
      {({
        id,
        className,
        onClick,
        onDblClick,
        onMouseEnter,
        onMouseLeave,
        content,
        ariaLabel,
      }: {
        id: string;
        className: string;
        onClick: React.MouseEventHandler<HTMLDivElement>;
        onDblClick: React.MouseEventHandler<HTMLDivElement>;
        onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
        onMouseLeave: React.MouseEventHandler<HTMLDivElement>;
        content: any;
        ariaLabel: string;
      }) => (
        <nav id={id} aria-label={ariaLabel}>
          <div
            onClick={onClick}
            onDoubleClick={onDblClick}
            className={className}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {content}
          </div>
        </nav>
      )}
    </ComboBoxInputBase>
  );
};

export default React.forwardRef(ComboBoxInputNavigationSkin);
